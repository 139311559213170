<template>
  <div :class="{ opacity50: disabled }">
    <strong>{{ getProductName() }}</strong>
    <div>{{ getVariantName() }}</div>
  </div>
</template>

<script>
import get from "lodash/get";
import { getTranslation } from "../../../helpers/common";
import { ORDER_STATUS } from "../../../config/global";
export default {
  name: "MarketOrderTableVariantTitle",

  props: {
    marketId: { type: Number },
  },

  data() {
    return {
      data: {},
    };
  },
  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
    disabled() {
      return this.data.status === ORDER_STATUS.CANCELED;
    },
  },
  methods: {
    getProductName() {
      const translations = get(
        this.data,
        "ppm.lot.productproducer.translations"
      );
      return getTranslation(translations, this.lang);
    },
    getVariantName() {
      const translations = get(this.data, "ppm.lot.translations");
      return getTranslation(translations, this.lang);
    },
  },
};
</script>
